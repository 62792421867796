import { WidgetTypes } from "@superblocksteam/shared";
import {
  PropertyPaneConfig,
  PropsPanelCategory,
} from "legacy/constants/PropertyControlConstants";
import { getWidgetIcon } from "legacy/pages/Editor/Explorer/ExplorerIcons";
import { iconPositionProperty, iconProperty } from "../appearanceProperties";
import { isDisabledProperty, isVisibleProperty } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import {
  appProperty,
  keepExistingQueryParamsProperty,
  linkToProperty,
  openInNewTabProperty,
  pageProperties,
  queryParamsProperty,
  isApp,
  isPage,
  isCustomUrl,
  urlProperty,
} from "../navigationProperties";
import { ButtonMenuItem, LinkMenuItem, MenuItemType } from "./types";

type Props = Omit<LinkMenuItem, "type"> &
  Omit<ButtonMenuItem, "type"> & {
    type: LinkMenuItem["type"] | ButtonMenuItem["type"];
  };

const isLinkType = (props: Props) => props.type === MenuItemType.Link;

const isButtonType = (props: Props) => props.type === MenuItemType.Button;

export const MENU_ITEM_PROPERTIES: PropertyPaneConfig[] = [
  {
    sectionName: "Content",
    sectionCategory: PropsPanelCategory.Content,
    children: [
      {
        propertyName: "label",
        label: "Label",
        controlType: "INPUT_TEXT",
        helpText: "Sets the label of the menu item",
        placeholderText: "Enter label text",
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        propertyName: "type",
        label: "Type",
        controlType: "RADIO_BUTTON_GROUP",
        options: [
          {
            value: MenuItemType.Link,
            label: "Link",
            icon: getWidgetIcon(WidgetTypes.LINK_WIDGET, 16),
          },
          {
            value: MenuItemType.Button,
            label: "Button",
            icon: getWidgetIcon(WidgetTypes.BUTTON_WIDGET, 16),
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
      },
      linkToProperty({ hidden: isButtonType }),
      ...pageProperties({
        hidden: (props) => isButtonType(props) || !isPage(props),
      }),
      appProperty({
        hidden: (props) => isButtonType(props) || !isApp(props),
      }),
      urlProperty({
        propertyName: "href",
        hidden: (props) => isButtonType(props) || !isCustomUrl(props),
      }),
      {
        propertyName: "manualChildren",
        label: "Submenu items",
        headerControlType: "ADD_MENU_ITEM",
        helpText: "Menu items to be displayed on this submenu",
        controlType: "MENU_ITEMS_INPUT",
        isBindProperty: false,
        isTriggerProperty: false,
      },
    ],
  },
  {
    sectionName: "Appearance",
    sectionCategory: PropsPanelCategory.Appearance,
    children: [iconProperty(), iconPositionProperty()],
  },
  {
    sectionName: "Layout",
    sectionCategory: PropsPanelCategory.Layout,
    children: [isVisibleProperty({ useJsExpr: true })],
  },
  {
    sectionName: "Interaction",
    sectionCategory: PropsPanelCategory.Interaction,
    children: [
      queryParamsProperty({
        hidden: (props) => isButtonType(props) || isCustomUrl(props),
      }),
      keepExistingQueryParamsProperty({
        hidden: (props) => isButtonType(props),
        isBindProperty: false,
        propertyName: "keepQueryParams",
        defaultValue: false,
      }),
      openInNewTabProperty({
        hidden: isButtonType,
        propertyName: "targetBlank",
        isBindProperty: false,
        defaultValue: false,
      }),
      isDisabledProperty({ useJsExpr: true }),
    ],
  },
  {
    sectionName: "Actions",
    sectionCategory: PropsPanelCategory.EventHandlers,
    hidden: isLinkType,
    children: [
      getPopoverConfig(
        "onClick",
        "Triggers an action when the button is clicked",
      ),
    ],
  },
];
