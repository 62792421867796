import {
  MenuItem,
  ControlGroup,
  Classes,
  Icon,
  Menu,
  PopoverProps,
  HTMLInputProps,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  ItemRendererProps,
  Suggest,
  ItemListRendererProps,
} from "@blueprintjs/select";
import equal from "@superblocksteam/fast-deep-equal/es6";
import { Dimension, IconColorBlock, Padding } from "@superblocksteam/shared";
import { Spin, Tooltip } from "antd";
import Fuse from "fuse.js";
import { findIndex, isNil, isEmpty, isNumber } from "lodash";
import React, { createRef, ReactNode } from "react";
import { FixedSizeList } from "react-window";
import styled, { css } from "styled-components";
import { ReactComponent as PlusCircleIcon } from "assets/icons/common/cross-xs.svg";
import DynamicSVG from "components/ui/DynamicSVG";
import { getCaretIconEncodedUrl } from "components/ui/IconButtons/ChevronDownEncodedUrl";
import { SpanTruncMiddle } from "components/ui/SpanTruncMiddle";
import { ComponentProps } from "legacy/components/designSystems/default/BaseComponent";
import {
  FormInputWithErrorWrapperStyle,
  FormInputWithHeightGrowStyle,
} from "legacy/components/editorComponents/ErrorInlineMessage";
import WidgetErrorsWrapper from "legacy/components/editorComponents/WidgetErrorsWrapper";
import { Layers } from "legacy/constants/Layers";
import {
  ErrorMessagePlacement,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import PaddingOverlay from "legacy/pages/Editor/CanvasArenas/PaddingOverlay";
import { APP_MODE } from "legacy/reducers/types";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { DROPDOWN_PADDING } from "legacy/themes/constants";
import { CheckboxInput } from "../Shared/CheckboxInput";
import { getLabelWidthCssValue, labelStyle } from "../Shared/widgetLabelStyles";
import { generatePaddingVariableDeclarationCss } from "../base/generatePaddingStyle";
import { getIconSVGElement } from "../iconUtils";
import { DEFAULT_ICON_SIZE } from "../shared";
import { MultiDropDown, MORE_TAGS_VALUE } from "./MultiDropdown";
import { DropdownOption } from "./types";
import {
  createCssVariablesForPadding,
  getClearableIconRight,
  generateIconTop,
  getDropdownIconPositionStyle,
} from "./utils";

const getFuseOptions = (fuzzySearch?: boolean) => {
  return {
    shouldSort: true,
    threshold: fuzzySearch === false ? 0.0 : 0.3,
    ignoreLocation: true,
    minMatchCharLength: 1,
    findAllMatches: true,
    keys: ["label", "value"],
  };
};

const MIN_ITEM_HEIGHT = 34;
const MAX_RENDER_MENU_ITEMS_HEIGHT = 300;
const ITEM_PADDING = 6;

// these are awkward values but they are needed so that fit content height lines up exactly with fixed height for default configured inputs
export const INPUT_ADDITIONAL_MIN_HEIGHT = 17.7;
const INPUT_MULTISELECT_ADDITIONAL_MIN_HEIGHT = 9.7;
export const LABEL_EXTRA_HEIGHT_MARGIN = 4;

const SingleDropDown = Suggest.ofType<DropdownOption>();
const StyledSingleDropDown = styled(SingleDropDown)<{
  hasIcon: boolean;
  inputPadding?: Padding;
  clearable?: boolean;
  vertical?: boolean;
  iconSize?: number;
  caretIconColor: IconColorBlock;
}>`
  height: 100%;
  div {
    flex: 1 1 auto;
  }
  span {
    width: 100%;
    position: relative;
  }
  ::before {
    display: block;
    position: absolute;
    cursor: pointer;
    pointer-events: none;
    ${({ inputPadding }) =>
      inputPadding
        ? css`
            right: ${inputPadding.right?.value ?? 0}px;
          `
        : css`
            right: ${DROPDOWN_PADDING.right.value}px;
          `};
    top: ${({ vertical, inputPadding, iconSize }) =>
      generateIconTop({ vertical, inputPadding, iconSize })};
    height: ${({ iconSize }) => `${iconSize ?? DEFAULT_ICON_SIZE}px`};
    width: ${({ iconSize }) => `${iconSize ?? DEFAULT_ICON_SIZE}px`};
    z-index: 99;

    content: "" !important;
    background-image: ${({ caretIconColor }) =>
      `url("${getCaretIconEncodedUrl(caretIconColor.iconColor.default)}")`};
    background-repeat: no-repeat;
    background-size: ${({ iconSize }) =>
      `${iconSize ?? DEFAULT_ICON_SIZE}px ${iconSize ?? DEFAULT_ICON_SIZE}px`};
  }

  &.${Classes.POPOVER_OPEN} {
    ::before {
      transform: rotate(180deg);
      background-image: ${({ caretIconColor }) =>
        `url("${getCaretIconEncodedUrl(caretIconColor.iconColor.active ?? caretIconColor.iconColor.default)}")`};
    }
  }

  .${Classes.INPUT} {
    ${generatePaddingVariableDeclarationCss()};

    height: 100%;
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-shadow: none;
    min-height: 32px;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

interface StyledCrossIconProps {
  vertical?: boolean;
  inputPadding?: Padding;
  iconSize?: number;
  right: number;
}

const StyledCrossIcon = styled(PlusCircleIcon)<StyledCrossIconProps>`
  position: absolute;
  right: ${({ right }) => `${right}px`};
  top: ${({ vertical, inputPadding, iconSize }) =>
    generateIconTop({ vertical, inputPadding, iconSize })};
  height: ${({ iconSize }) => `${iconSize ?? DEFAULT_ICON_SIZE}px`};
  width: ${({ iconSize }) => `${iconSize ?? DEFAULT_ICON_SIZE}px`};
  z-index: ${Layers.focusedWidget + 1};
  cursor: pointer;
`;

const StyledIconSpan = styled.span<{
  inputPadding?: Padding;
  vertical?: boolean;
  iconSize?: number;
}>`
  position: absolute;
  top: ${({ vertical, inputPadding, iconSize }) =>
    generateIconTop({ vertical, inputPadding, iconSize })};
  z-index: ${Layers.max};
`;

const StyledControlGroup = styled(ControlGroup)<{ $verticalLabel: boolean }>`
  height: 100%;
  &&& > {
    .dropdown-wrapper {
      min-height: 0px; // this is needed to shrink the dropdown in a flex layout
      position: relative;
      width: 100%;
      margin-right: 0;
      margin-top: 0;
      position: relative;
      .${Classes.INPUT_GROUP} {
        height: 100%;
      }
    }
    label {
      ${(props) =>
        props.vertical
          ? css`
              margin: 12px ${WIDGET_PADDING * 2}px 9px 0;
            `
          : css`
              ${labelStyle.horizontal}
              flex-basis: var(--label-width);
            `}

      align-self: flex-start;
      flex-shrink: 0;
      flex-grow: 0;
      text-align: left;
      max-width: ${(props) =>
        props.$verticalLabel
          ? `calc(var(--label-width) - ${WIDGET_PADDING}px)`
          : "100%"};
    }
    span {
      max-width: ${(props) =>
        props.$verticalLabel
          ? `calc(var(--label-width) - ${WIDGET_PADDING}px)`
          : "100%"};
    }
  }

  > div .dropdown-wrapper {
    position: relative;
  }

  .${Classes.INPUT_GROUP} {
    flex: 1 1 0;
  }
`;

const StyledTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const SELECT_ALL_BTN_HEIGHT = 26;

const SelectAllButton = styled.button`
  height: ${SELECT_ALL_BTN_HEIGHT}px;
  text-align: left;
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  margin-bottom: 4px;
`;

const SingleSelectOptionItem = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const MultiSelectOptionItem = styled.span`
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.span`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;

const IconContainer = styled.span`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  padding-left: 4px;
  padding-right: 8px;
`;

interface DropDownComponentState {
  activeItemIndex: number | undefined;
  isFocused: boolean;
  query?: string;
  isPopoverOpen: boolean;
}

class DropDownComponent extends React.PureComponent<
  SingleDropDownComponentProps | MultiselectDropDownComponentProps,
  DropDownComponentState
> {
  listRef = createRef<FixedSizeList<any>>();

  state = {
    // used to show focused item for keyboard up down key interection
    activeItemIndex: -1,
    isFocused: false,
    query: "",
    isPopoverOpen: false,
  };

  componentDidMount = () => {
    let newState: DropDownComponentState;

    if (this.props.selectionType === "SINGLE_SELECT") {
      newState = {
        activeItemIndex: this.props.selectedIndex,
        isFocused: false,
        query: "",
        isPopoverOpen: false,
      };
    } else {
      newState = {
        activeItemIndex:
          this.props.selectedIndexArr[this.props.selectedIndexArr.length - 1],
        isFocused: false,
        query: "",
        isPopoverOpen: false,
      };
    }

    // set default selectedIndex as focused index
    this.setState(newState);
  };

  componentDidUpdate = (prevProps: DropDownComponentProps) => {
    if (
      prevProps.selectionType === "SINGLE_SELECT" &&
      this.props.selectionType === "SINGLE_SELECT"
    ) {
      if (
        prevProps.selectedIndex !== this.props.selectedIndex &&
        this.state.activeItemIndex !== this.props.selectedIndex
      ) {
        // update focus index if selectedIndex changed by property pane
        this.setState({ activeItemIndex: this.props.selectedIndex });
      }
    } else if (
      prevProps.selectionType === "MULTI_SELECT" &&
      this.props.selectionType === "MULTI_SELECT"
    ) {
      if (
        !equal(prevProps.selectedIndexArr, this.props.selectedIndexArr) &&
        this.state.activeItemIndex !==
          this.props.selectedIndexArr[this.props.selectedIndexArr.length - 1]
      ) {
        this.setState({
          activeItemIndex:
            this.props.selectedIndexArr[this.props.selectedIndexArr.length - 1],
        });
      }
    } else {
      // We're changing dropdown types, just reset back to 0
      this.setState({ activeItemIndex: 0 });
    }
  };

  getFilteredItems() {
    return this.listRef.current?.props.itemData.items;
  }

  handleActiveItemChange = (activeItem: DropdownOption | null) => {
    // Update state.activeItemIndex if activeItem is different from the current value
    if (
      activeItem?.value !==
      this.props?.options[this.state.activeItemIndex]?.value
    ) {
      // find new index from options
      const activeItemIndex = findIndex(this.props.options, [
        "label",
        activeItem?.label,
      ]);

      this.setState({ activeItemIndex });

      if (this.props.selectionType === "SINGLE_SELECT") {
        // This removes the query from being shown on single selects when no option was selected (not sure why)
        // The query is saved until the user clicks the dropdown again
        this.listRef.current?.scrollToItem(0);
      }
    }
  };

  onFocus = () => {
    this.props.onFocusChange?.(true);
    this.setState({ isFocused: true });
  };

  onBlur = () => {
    this.props.onFocusChange?.(false);
    this.setState({ isFocused: false });
  };

  handlePopoverOpening = () => {
    this.setState({ isFocused: true });
    if (this.props.selectionType === "MULTI_SELECT" && this.props.disabled) {
      // NOTE(aayush): This is a hack to prevent the popover from opening when the dropdown is
      // disabled. This is a workaround for a bug in blueprint where clicking on any of the items in
      // a disabled multi-select dropdown opens the dropdown popover.
      return;
    }
    const query = this.props.query || this.state.query;
    if (query) {
      // If there is a query, reset the activeItemIndex to the first filtered item
      const filteredItems = this.getFilteredItems();

      const scrollToActiveItemIndex = findIndex(
        filteredItems || this.props.options,
        ["label", filteredItems[0]?.label],
      );
      this.setState({ activeItemIndex: scrollToActiveItemIndex });
    } else {
      if (
        this.props.selectionType === "SINGLE_SELECT" &&
        this.props.selectedIndex !== -1 &&
        !isNil(this.props.selectedIndex)
      ) {
        // Scroll to the currently selected item when opening if there is no query
        this.setState({ activeItemIndex: this.props.selectedIndex });
        this.listRef.current?.scrollToItem(this.props.selectedIndex, "center");
      } else if (this.props.selectionType === "MULTI_SELECT") {
        if (this.props.selectedIndexArr[0]) {
          this.setState({ activeItemIndex: this.props.selectedIndexArr[0] });
          this.listRef.current?.scrollToItem(
            this.props.selectedIndexArr[0],
            "center",
          );
        }
      }
    }
  };

  handlePopoverClose = () => {
    // Reset query after selection of an item for multi-select
    // using resetOnQuery causes the dropdown to close which we don't want
    if (this.props.selectionType === "MULTI_SELECT") {
      this.props.onSearchTextChange?.("");
      this.setState({ query: "" });
    }
  };

  handlePopoverClosing = () => {
    typeof this.props.onDropdownClosed === "function" &&
      this.props.onDropdownClosed();
  };

  handleQueryChanged = (newQuery: string, event: any) => {
    const query = this.props.query || this.state.query;
    let hasQueryChanged = false;
    if (query !== newQuery) {
      hasQueryChanged = true;
    }
    // NOTE(aayush): This `event` check is a hack to filter out duplicate events
    // that are fired by the blueprint component. This is a known issue in the
    // blueprint library and they have not fixed it yet.
    // See: https://github.com/palantir/blueprint/issues/2983
    if (hasQueryChanged && event) {
      this.setState({ query: newQuery });
      if (this.props.onSearchTextChange) {
        // NB: the onSearchTextChange handler is generally expected to update the
        // `query` prop.
        this.props.onSearchTextChange(newQuery);
      }
    }
  };

  getIconClassNames = (hasSelection?: boolean) => {
    return `${CLASS_NAMES.ICON} ${
      hasSelection ? "" : CLASS_NAMES.DISABLED_MODIFIER
    } ${this.state.isFocused ? CLASS_NAMES.ACTIVE_MODIFIER : ""}`;
  };

  render() {
    const { options } = this.props;
    const selectedItems =
      "selectedIndexArr" in this.props && this.props.selectedIndexArr
        ? this.props.selectedIndexArr.map((index) => options[index])
        : [];
    const selectedIndex =
      "selectedIndex" in this.props ? this.props.selectedIndex : -1;

    const activeItem = () => {
      if (
        this.state.activeItemIndex === -1 ||
        isNil(this.state.activeItemIndex)
      )
        return undefined;
      if (!isEmpty(this.props.options))
        return this.props.options[this.state.activeItemIndex];
    };

    const basePopoverProps: Partial<PopoverProps> = {
      minimal: true,
      usePortal: true,
      popoverClassName: `${CLASS_NAMES.DROPDOWN} ${CLASS_NAMES.POPOVER_WRAPPER}`,
      //Allow dropdown to overflow its container and placed based on viewport
      rootBoundary: "viewport",
      onOpening: this.handlePopoverOpening,
      onClosing: this.handlePopoverClosing,
      portalClassName: this.props.portalClassname,
    };

    const controlledPopoverProps: Partial<PopoverProps> = {
      isOpen: this.props.forceOpen,
    };
    const defaultIconElement = this.props.icon ? (
      <StyledIconSpan
        className={this.getIconClassNames(
          selectedIndex > -1 || selectedItems.length > 0,
        )}
        style={getDropdownIconPositionStyle(this.props.inputPadding)}
        inputPadding={this.props.inputPadding}
        vertical={this.props.vertical}
        iconSize={this.props.iconSize}
      >
        <DynamicSVG iconName={this.props.icon} size={this.props.iconSize} />
      </StyledIconSpan>
    ) : null;

    // If the selectedItem has an icon, then use that, otherwise use the default icon
    const singleSelectDropdownIconElement =
      selectedIndex > -1 && this.props.options[selectedIndex]?.icon ? (
        <StyledIconSpan
          className={this.getIconClassNames(
            selectedIndex > -1 || selectedItems.length > 0,
          )}
          inputPadding={this.props.inputPadding}
          vertical={this.props.vertical}
        >
          {getIconSVGElement(this.props.options[selectedIndex].icon)}
        </StyledIconSpan>
      ) : (
        defaultIconElement
      );

    const tagOptionIconElement = (tagIndex: number) => {
      if (selectedItems[tagIndex]?.icon) {
        return getIconSVGElement(selectedItems[tagIndex].icon);
      }
      return null;
    };

    const errorMessagePlacement =
      this.props.errorMessagePlacement ?? ErrorMessagePlacement.TOOLTIP;

    const paddingVarDeclarations = createCssVariablesForPadding(
      !!this.props.allowClearing,
      !!this.props.icon,
      this.props.inputPadding,
      this.props.iconSize,
    );
    const clearableIconRight = getClearableIconRight(
      !!this.props.allowClearing,
      this.props.inputPadding,
      this.props.iconSize,
    );

    const styleVars: Record<string, unknown> = {
      "--label-width": getLabelWidthCssValue(this.props.labelWidth),
    };

    return (
      <WidgetErrorsWrapper
        widgetId={this.props.widgetId}
        showError={!!(this.props.showError && !this.state.isFocused)}
        errorMessagePlacement={errorMessagePlacement}
        messages={this.props.validationError}
        isFocused={this.state.isFocused}
      >
        {(inlineError) => (
          <StyledControlGroup
            fill
            $verticalLabel={Boolean(this.props.label) && !this.props.vertical}
            vertical={this.props.vertical}
            style={styleVars}
          >
            {this.props.label ? (
              <label
                className={`${CLASS_NAMES.ELLIPSIS_TEXT} ${
                  this.props.labelClassName ?? CLASS_NAMES.INPUT_LABEL
                } ${this.props.isDisabled ? CLASS_NAMES.DISABLED_MODIFIER : ""} ${
                  this.props.isLoading ? "test-loading-dropdown" : ""
                }`}
                style={this.props.labelStyleOverride}
              >
                {this.props.isRequired &&
                  this.props.label.indexOf("*") === -1 && (
                    <span className={CLASS_NAMES.ERROR_MODIFIER}>* </span>
                  )}
                {this.props.label}
              </label>
            ) : null}
            <div style={FormInputWithErrorWrapperStyle}>
              {this.props.selectionType === "SINGLE_SELECT" ? (
                <div
                  style={{
                    ...FormInputWithHeightGrowStyle,
                    ...paddingVarDeclarations,
                  }}
                  className={
                    this.props.isLoading
                      ? "dropdown-wrapper test-loading-dropdown"
                      : "dropdown-wrapper"
                  }
                >
                  <PaddingOverlay
                    layer={Layers.focusedInput + 1}
                    padding={this.props.inputPadding}
                    widgetId={this.props.widgetId}
                    parentId={this.props.parentId}
                    appMode={this.props.appMode ?? APP_MODE.PREVIEW}
                  />
                  <StyledSingleDropDown
                    items={this.props.options}
                    itemRenderer={this.renderSingleSelectItem}
                    onItemSelect={this.onItemSelect}
                    scrollToActiveItem={false}
                    resetOnSelect
                    resetOnQuery
                    query={this.props.query || this.state.query}
                    onQueryChange={this.handleQueryChanged}
                    itemsEqual={equal}
                    onActiveItemChange={this.handleActiveItemChange}
                    activeItem={activeItem()}
                    disabled={this.props.disabled}
                    itemDisabled={"disabled"}
                    inputValueRenderer={({ label }) => label}
                    itemListRenderer={this.renderDropdown}
                    inputPadding={this.props.inputPadding}
                    clearable={this.props.allowClearing}
                    inputProps={
                      {
                        placeholder:
                          this.props.placeholder || "Select an option",
                        type: "search",
                        "data-test": this.props.dataTest,
                        onBlur: this.onBlur,
                        onFocus: this.onFocus,
                        autoFocus: this.props.forceOpen,
                        className: `${CLASS_NAMES.INPUT} ${CLASS_NAMES.CARET_ICON} ${
                          this.props.isInvalid ? CLASS_NAMES.ERROR_MODIFIER : ""
                        }`,
                        style: {
                          ...this.props.inputStyleOverride,
                          minHeight: this.props.inputLineHeightPx
                            ? `${
                                this.props.inputLineHeightPx +
                                INPUT_ADDITIONAL_MIN_HEIGHT
                              }px`
                            : undefined,
                        },
                      } as any
                    }
                    selectedItem={
                      !isEmpty(this.props.options) &&
                      selectedIndex !== undefined &&
                      selectedIndex > -1
                        ? (this.props.options[selectedIndex] ?? null)
                        : null
                    }
                    popoverProps={{
                      ...basePopoverProps,
                      ...(this.props.forceOpen ? controlledPopoverProps : {}),
                    }}
                    itemListPredicate={this.itemListPredicate}
                    hasIcon={Boolean(singleSelectDropdownIconElement)}
                    vertical={this.props.vertical}
                    iconSize={this.props.iconSize}
                    caretIconColor={this.props.caretIconColor}
                  />
                  {singleSelectDropdownIconElement}
                  {this.props.allowClearing && selectedIndex > -1 && (
                    <StyledCrossIcon
                      onClick={this.onClear}
                      data-test={this.props.dataTest + "-single-clear"}
                      className={CLASS_NAMES.DROPDOWN_CLEAR_ICON}
                      vertical={this.props.vertical}
                      inputPadding={this.props.inputPadding}
                      iconSize={this.props.iconSize}
                      right={clearableIconRight}
                      style={
                        this.props.disabled
                          ? {
                              cursor: "not-allowed",
                            }
                          : {}
                      }
                    />
                  )}
                </div>
              ) : (
                <div
                  className={
                    this.props.isLoading
                      ? "dropdown-wrapper test-loading-dropdown"
                      : "dropdown-wrapper"
                  }
                  style={{
                    ...FormInputWithHeightGrowStyle,
                    ...paddingVarDeclarations,
                  }}
                >
                  <PaddingOverlay
                    layer={Layers.focusedInput + 1}
                    padding={this.props.inputPadding}
                    widgetId={this.props.widgetId}
                    parentId={this.props.parentId}
                    appMode={this.props.appMode ?? APP_MODE.PREVIEW}
                  />
                  <MultiDropDown
                    overflowTags={this.props.overflowTags}
                    items={this.props.options}
                    labelHeight={this.props.vertical ? 26 : 0}
                    scrollToActiveItem={false}
                    resetOnSelect={false}
                    resetOnQuery={false}
                    itemListPredicate={this.itemListPredicate}
                    placeholder={this.props.placeholder}
                    tagRenderer={this.renderTag}
                    itemRenderer={this.renderMultiSelectItem}
                    activeItem={activeItem()}
                    itemsEqual={equal}
                    selectedItems={selectedItems}
                    itemListRenderer={this.renderDropdown}
                    query={this.props.query || this.state.query}
                    onQueryChange={this.handleQueryChanged}
                    className={`${CLASS_NAMES.TAG_INPUT} ${
                      this.props.isInvalid ? CLASS_NAMES.ERROR_MODIFIER : ""
                    } ${this.props.disabled ? CLASS_NAMES.DISABLED_MODIFIER : ""}`}
                    inputPadding={this.props.inputPadding}
                    tagInputProps={{
                      onRemove: this.onItemRemoved,
                      tagProps: (tag, index) => {
                        // tags are react nodes to help with the internal overflow logic
                        // and since the tag is a react node (see renderTag), we need to extract the data prop to get the actual value
                        const value =
                          typeof tag === "object" &&
                          tag != null &&
                          "props" in tag &&
                          tag.props?.["data-value"]
                            ? tag.props?.["data-value"]
                            : "";

                        return {
                          minimal: true,
                          interactive: true,
                          "data-tag-value": value,
                          "data-test": `${this.props.dataTest}-${value}`,
                          icon: tagOptionIconElement(index),
                          style: {
                            ...this.props.inputStyleOverride,
                            minHeight: this.props.inputLineHeightPx
                              ? `${
                                  this.props.inputLineHeightPx +
                                  INPUT_MULTISELECT_ADDITIONAL_MIN_HEIGHT
                                }px`
                              : undefined,
                          },
                        };
                      },
                      disabled: this.props.disabled,
                      fill: true,
                      rightElement: <Icon icon={IconNames.CHEVRON_DOWN} />,
                      inputProps: {
                        type: "search",
                        "data-test": this.props.dataTest,
                        onBlur: this.onBlur,
                        onFocus: this.onFocus,
                        autoFocus: this.props.forceOpen,
                        style: {
                          ...this.props.inputStyleOverride,
                          minHeight:
                            this.state.isFocused && this.props.inputLineHeightPx
                              ? `${
                                  this.props.inputLineHeightPx +
                                  INPUT_MULTISELECT_ADDITIONAL_MIN_HEIGHT
                                }px`
                              : undefined,
                        } satisfies React.CSSProperties,
                      } as HTMLInputProps,
                    }}
                    onItemSelect={this.onItemSelect}
                    onActiveItemChange={this.handleActiveItemChange}
                    popoverProps={{
                      ...basePopoverProps,
                      // This ensures that clicking the input does not close the dropdown.
                      // Closing on input click sometimes causes the dropdown to close clicking
                      // options, plus there is no caret and no expected UX that it closes on click
                      // like we have with single select
                      ...(this.props.forceOpen ? controlledPopoverProps : {}),
                      hasBackdrop: false,
                      onClose: this.handlePopoverClose,
                    }}
                    width={this.props.width}
                    isValid={!this.props.isInvalid}
                    canvasMode={this.props.canvasMode}
                    hasIcon={Boolean(this.props.icon)}
                    inputHeightPx={this.props.inputHeightPx}
                  />
                  {defaultIconElement}
                  {this.props.allowSelectAll && selectedItems.length > 0 && (
                    <StyledCrossIcon
                      onClick={() => this.handleSelectAll(true)}
                      data-test={this.props.dataTest + "-clear"}
                      className={CLASS_NAMES.DROPDOWN_CLEAR_ICON}
                      vertical={this.props.vertical}
                      inputPadding={this.props.inputPadding}
                      iconSize={this.props.iconSize}
                      right={
                        this.props.inputPadding?.right?.value ??
                        DROPDOWN_PADDING.right.value
                      }
                      style={
                        this.props.disabled
                          ? {
                              cursor: "not-allowed",
                            }
                          : {}
                      }
                    />
                  )}
                </div>
              )}
              {inlineError}
            </div>
          </StyledControlGroup>
        )}
      </WidgetErrorsWrapper>
    );
  }

  itemListPredicate = (query: string, items: DropdownOption[]) => {
    const clientSideFiltering = this.props.clientSideFiltering ?? true;
    if (!clientSideFiltering) {
      // If client side filtering is disabled, return all items since they're
      // presumably being filtered server-side.
      return items;
    }
    const fuse = new Fuse(items, getFuseOptions(this.props.fuzzySearch));
    const filteredItems = query
      ? fuse.search(query).map(({ item }) => item)
      : items;
    return filteredItems;
  };

  onItemSelect = (item: DropdownOption): void => {
    this.props.onOptionSelected(item);
  };

  onItemRemoved = (_tag: ReactNode, index: number) => {
    if (this.props.selectionType === "MULTI_SELECT") {
      this.props.onOptionRemoved(index);
    }
  };

  onClear = (): void => {
    if (this.props.disabled) {
      return;
    }
    this.props.onOptionSelected(null);
    this.setState({ activeItemIndex: -1, query: "" });
    this.props.onSearchTextChange?.("");
  };

  renderTag = (option: DropdownOption) => {
    if (option.value === MORE_TAGS_VALUE) {
      return (
        <Tooltip
          data-value={option.value}
          overlayClassName={CLASS_NAMES.TOOLTIP}
          title={
            <StyledTagContainer>
              {option.hiddenLabels?.map((label) => (
                <span
                  style={this.props.inputStyleOverride}
                  className={CLASS_NAMES.TAG_INPUT}
                  key={label}
                >
                  {label}
                </span>
              ))}
            </StyledTagContainer>
          }
        >
          {option.label}
        </Tooltip>
      );
    }

    return <span data-value={option.value}>{option.label}</span>;
  };

  isOptionSelected = (selectedOption: DropdownOption) => {
    const optionIndex = findIndex(this.props.options, (option) => {
      return option.value === selectedOption.value;
    });
    if (this.props.selectionType === "SINGLE_SELECT") {
      return optionIndex === this.props.selectedIndex;
    } else {
      return (
        findIndex(this.props.selectedIndexArr, (index) => {
          return index === optionIndex;
        }) !== -1
      );
    }
  };

  renderSingleSelectItem = (
    option: DropdownOption,
    itemProps: ItemRendererProps,
  ) => {
    if (!itemProps.modifiers.matchesPredicate) {
      return null;
    }
    const isSelected: boolean = this.isOptionSelected(option);
    const optionIcon = option.icon ? getIconSVGElement(option.icon) : <></>;
    const optionItemComponent = option.icon ? (
      <SingleSelectOptionItem>
        {optionIcon}
        {option.label}
      </SingleSelectOptionItem>
    ) : (
      option.label
    );
    return (
      <MenuItem
        className={`single-select ${isSelected && "selected"} ${
          this.props.inputClassName
        }`}
        active={itemProps.modifiers.active}
        key={option.value}
        onClick={itemProps.handleClick}
        text={optionItemComponent}
        disabled={Boolean(option.disabled)}
        data-test={`${this.props.dataTest}-item-${option.label}`}
        style={this.props.inputStyleOverride}
      />
    );
  };

  renderMultiSelectItem = (
    option: DropdownOption,
    itemProps: ItemRendererProps,
  ) => {
    if (!itemProps.modifiers.matchesPredicate) {
      return null;
    }
    const isSelected: boolean = this.isOptionSelected(option);
    const optionIcon = option.icon ? getIconSVGElement(option.icon) : <></>;
    const content: ReactNode = (
      <div
        data-test={`${this.props.dataTest}-item-${option.label}`}
        style={{ display: "flex", gap: "8px", alignItems: "center" }}
      >
        <CheckboxInput
          isDisabled={false}
          isValid={true}
          isChecked={isSelected}
        />
        <span title={option.label} style={{ flex: 1 }}>
          <SpanTruncMiddle text={option.label ?? ""} />
        </span>
      </div>
    );
    const optionItemComponent = option.icon ? (
      <MultiSelectOptionItem>
        <ContentContainer>{content}</ContentContainer>
        <IconContainer>{optionIcon}</IconContainer>
      </MultiSelectOptionItem>
    ) : (
      content
    );
    return (
      <MenuItem
        className={`multi-select ${isSelected && "selected"} ${
          this.props.inputClassName
        }`}
        active={itemProps.modifiers.active}
        key={option.value}
        text={optionItemComponent}
        onClick={itemProps.handleClick}
        style={this.props.inputStyleOverride}
      />
    );
  };

  handleSelectAll = (clearAll?: boolean) => {
    if (this.props.disabled) {
      return;
    }
    if (this.props.selectionType === "MULTI_SELECT") {
      const isAllSelected =
        this.props.selectedIndexArr.length > 0 &&
        this.props.selectedIndexArr.length === this.props.options.length;
      if (clearAll || isAllSelected) {
        this.props.onSelectAll?.([]);
      } else {
        this.props.onSelectAll?.(this.props.options);
      }
    }
  };

  shouldShowSelectAll = () => {
    return (
      this.props.selectionType === "MULTI_SELECT" && this.props.allowSelectAll
    );
  };

  renderMultiSelectAll = () => {
    if (!this.shouldShowSelectAll()) return null;

    const props = this.props as MultiselectDropDownComponentProps;

    const isAllSelected =
      props.selectedIndexArr.length > 0 &&
      props.selectedIndexArr.length === props.options.length;

    return (
      <SelectAllButton
        onClick={() => this.handleSelectAll()}
        className={CLASS_NAMES.SYSTEM_BUTTON}
        style={{ padding: "4px", cursor: "pointer" }}
      >
        {isAllSelected ? "Deselect all" : "Select all"}
      </SelectAllButton>
    );
  };

  renderDropdown = ({
    filteredItems,
    itemsParentRef,
    renderItem,
    query,
  }: ItemListRendererProps<DropdownOption>) => {
    let itemsToRender: DropdownOption[] = filteredItems;

    if (
      !filteredItems ||
      (filteredItems.length === 0 && !this.props.rowAboveOptions)
    ) {
      const noResultsMessage = this.props.noResultsText || "No results found";
      itemsToRender = [
        {
          label: noResultsMessage,
          value: noResultsMessage,
        },
      ];
    }
    const itemHeight = Math.max(
      MIN_ITEM_HEIGHT,
      (this.props.inputLineHeightPx ?? 0) + 2 * ITEM_PADDING,
    );

    const scrollOffset: number =
      !query &&
      isNumber(this.state.activeItemIndex) &&
      this.props.options.length * itemHeight > MAX_RENDER_MENU_ITEMS_HEIGHT
        ? this.state.activeItemIndex * itemHeight
        : 0;

    const widgetWidth = this.props.width || 0;
    const dropdownWidth = this.props.vertical ? widgetWidth : widgetWidth * 0.7;
    const menuStyle: React.CSSProperties = {
      width:
        dropdownWidth > 0 ? `${dropdownWidth - 2 * WIDGET_PADDING}px` : "auto",
      // Padding needs to be on each item to ensure scroll works properly
      padding: 0,
      // with react-window
      // Let react-window manage scroll
      overflowY: "hidden",
    };

    return (
      <div>
        <Spin
          spinning={this.props.isLoading ?? false}
          data-test={"dropdown-options-spinning"}
        >
          {this.renderMultiSelectAll()}
          <Menu ulRef={itemsParentRef} style={menuStyle}>
            {this.props.rowAboveOptions}
            <FixedSizeList
              ref={this.listRef}
              itemData={{
                renderItem,
                items: itemsToRender,
              }}
              className="menu-virtual-list"
              height={Math.min(
                MAX_RENDER_MENU_ITEMS_HEIGHT,
                itemsToRender.length * itemHeight,
              )}
              itemCount={itemsToRender.length}
              itemSize={itemHeight}
              width="100%"
              initialScrollOffset={scrollOffset}
            >
              {ListItemRenderer}
            </FixedSizeList>
          </Menu>
        </Spin>
      </div>
    );
  };
}

const ListItemRenderer = (itemProps: {
  index: number;
  style: React.CSSProperties;
  data: {
    renderItem: (item: DropdownOption, index: number) => JSX.Element | null;
    items: DropdownOption[];
  };
}) => {
  return (
    <div key={itemProps.index} style={itemProps.style}>
      {itemProps.data.renderItem(
        itemProps.data.items[itemProps.index],
        itemProps.index,
      )}
    </div>
  );
};
ListItemRenderer.displayName = "SelectListItemRenderer";

export interface SingleDropDownComponentProps extends ComponentProps {
  selectionType: "SINGLE_SELECT";
  vertical?: boolean;
  disabled?: boolean;
  onOptionSelected: (optionSelected: DropdownOption | null) => void;
  onFocusChange?: (focusState: boolean) => void;
  onSearchTextChange?: (searchText: string) => void;
  query?: string;
  placeholder?: string;
  label?: string;
  labelClassName?: string;
  labelStyleOverride?: React.CSSProperties;
  labelWidth?: Dimension<"px" | "gridUnit">;
  inputClassName?: string;
  inputStyleOverride?: React.CSSProperties;
  inputLineHeightPx?: number;
  inputPadding?: Padding;
  parentId?: string;
  selectedIndex: number;
  options: DropdownOption[];
  isLoading: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  validationError?: string;
  width?: number;
  allowClearing?: boolean;
  clientSideFiltering: boolean;
  rowAboveOptions?: React.ReactNode;
  noResultsText?: string;
  canvasMode?: boolean;
  fuzzySearch?: boolean;
  forceOpen?: boolean;
  onDropdownClosed?: () => void;
  portalClassname?: string;
  icon?: string;
  appMode?: APP_MODE;
  overflowTags?: boolean;
  errorMessagePlacement?: ErrorMessagePlacement;
  isTouched?: boolean;
  iconSize?: number;
  caretIconColor: IconColorBlock;
  showError?: boolean;
  inputHeightPx?: number;
}
export interface MultiselectDropDownComponentProps extends ComponentProps {
  selectionType: "MULTI_SELECT";
  vertical?: boolean;
  disabled?: boolean;
  onOptionSelected: (optionSelected: DropdownOption | null) => void;
  onOptionRemoved: (removedIndex: number) => void;
  onSelectAll?: (options: DropdownOption[]) => void;
  onFocusChange?: (focusState: boolean) => void;
  onSearchTextChange?: (searchText: string) => void;
  query?: string;
  placeholder?: string;
  label?: string;
  labelClassName?: string;
  labelStyleOverride?: React.CSSProperties;
  labelWidth?: Dimension<"px" | "gridUnit">;
  inputClassName?: string;
  inputStyleOverride?: React.CSSProperties;
  inputLineHeightPx?: number;
  inputPadding?: Padding;
  parentId?: string;
  selectedIndexArr: number[];
  options: DropdownOption[];
  isLoading: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  validationError?: string;
  width?: number;
  rowAboveOptions?: React.ReactNode;
  allowSelectAll?: boolean;
  clientSideFiltering: boolean;
  noResultsText?: string;
  canvasMode?: boolean;
  fuzzySearch?: boolean;
  forceOpen?: boolean;
  onDropdownClosed?: () => void;
  portalClassname?: string;
  icon?: string;
  appMode?: APP_MODE;
  overflowTags?: boolean;
  errorMessagePlacement?: ErrorMessagePlacement;
  isTouched?: boolean;
  iconSize?: number;
  allowClearing?: boolean;
  caretIconColor: IconColorBlock;
  showError?: boolean;
  inputHeightPx?: number;
}
export type DropDownComponentProps =
  | SingleDropDownComponentProps
  | MultiselectDropDownComponentProps;

export default DropDownComponent;
