import { Placement } from "@blueprintjs/core";
import {
  Dimension,
  PerCornerBorderRadius,
  PerSideBorder,
  WidgetTypes,
} from "@superblocksteam/shared";
import React, { useCallback, useMemo, useState } from "react";
import {
  PropertyPaneConfig,
  PropsPanelCategory,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { TextAlign } from "legacy/constants/WidgetConstants";
import {
  VALIDATION_TYPES,
  BASE_WIDGET_VALIDATION,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import {
  getCurrentPageUrlState,
  getRoutes,
} from "legacy/selectors/routeSelectors";
import { GeneratedTheme, TextStyleWithVariant } from "legacy/themes";
import { getSystemQueryParams } from "legacy/utils/queryParams";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { useAppSelector } from "store/helpers";
import { getComponentDimensions } from "utils/size";
import BaseWidget, {
  type RunWidgetEventHandlers,
  type WidgetPropsRuntime,
} from "../BaseWidget";
import { BUTTON_STYLE_OPTIONS } from "../ButtonWidget/constants";
import { ButtonStyle } from "../Shared/Button";
import { iconPositionProperty, iconProperty } from "../appearanceProperties";
import {
  getWidgetDefaultPadding,
  ZERO_PADDING,
} from "../base/sizing/canvasSizingUtil";
import {
  getApplicableMaxWidth,
  getApplicableMinWidth,
} from "../base/sizing/canvasWidthUtil";
import {
  isDisabledProperty,
  paddingProperty,
  sizeProperties,
  visibleProperties,
} from "../basePropertySections";
import {
  backgroundColorProperty,
  borderProperty,
  borderRadiusProperty,
  textStyleCombinedProperty,
} from "../styleProperties";
import withMeta, { WithMeta } from "../withMeta";
import MenuComponent, {
  type InteractionKind,
  type MenuComponentProps,
} from "./MenuComponent";
import {
  HORIZONTAL_PLACEMENT_OPTIONS,
  VERTICAL_PLACEMENT_OPTIONS,
} from "./constants";
import { transformItem } from "./transform";
import { ComponentMenuItem, ManualMenuItem, MenuChildrenType } from "./types";
import {
  borderDefaultValue,
  DEFAULT_MENU_WIDGET_BUTTON_STYLE_VARIANT,
  DEFAULT_MENU_WIDGET_MENU_ITEM_STYLE_VARIANT,
  textColorDefaultValue,
} from "./utils";
import type { AppState } from "store/types";

export interface MenuWidgetProps extends WidgetPropsRuntime, WithMeta {
  isDisabled?: boolean;
  isVisible?: boolean;
  childrenType: MenuChildrenType;
  manualChildren?: ManualMenuItem[];
  interactionKind: InteractionKind;

  runEventHandlers: (payload: RunWidgetEventHandlers) => void;

  // Button props
  buttonProps: {
    text?: string;
    textStyle?: TextStyleWithVariant;
    backgroundColor?: string;
    buttonStyle?: ButtonStyle;
    icon?: string;
    iconPosition?: "LEFT" | "RIGHT";
    textAlignment?: TextAlign;
    border?: PerSideBorder;
    borderRadius?: PerCornerBorderRadius;
    showCaret?: boolean;
  };

  menuProps: {
    orientation: "vertical" | "horizontal";
    placement: Placement;
    textStyle?: TextStyleWithVariant;
    width: Dimension;
  };
}

const InternalMenuWidget = (props: MenuWidgetProps) => {
  const routes = useAppSelector(getRoutes);
  const currentPageUrlState = useAppSelector(getCurrentPageUrlState);
  const systemQueryParams = useAppSelector(getSystemQueryParams);
  const appMode = useAppSelector(getAppMode);
  const isDraggingOrResizingWidget = useAppSelector(
    (state: AppState) =>
      state.legacy.ui.widgetDragResize.isDragging ||
      state.legacy.ui.widgetDragResize.isResizing,
  );
  const [isOpen, setIsOpen] = useState(false);
  const context = useMemo(() => {
    return {
      routes,
      currentPageUrlState,
      systemQueryParams,
      appMode,
    };
  }, [routes, currentPageUrlState, appMode, systemQueryParams]);

  // We re-calculate items when the menu is opened
  const [menuItems, setMenuItems] = useState<ComponentMenuItem[]>([]);
  const getMenuItems = useCallback(() => {
    return (props.manualChildren ?? []).map((child) =>
      transformItem(child, context),
    );
  }, [context, props.manualChildren]);

  const handleBeforeMenuOpen = useCallback(() => {
    setMenuItems(getMenuItems());
    setIsOpen(true);
  }, [getMenuItems, setIsOpen]);

  const handleOnClose = useCallback(
    (e?: React.SyntheticEvent<HTMLElement, Event>) => {
      const forceNewTab =
        e && "ctrlKey" in e && "metaKey" in e && (e.ctrlKey || e.metaKey);

      if (!forceNewTab) {
        // keep the menu open if ctrl/cmd is pressed
        setIsOpen(false);
        setMenuItems([]);
      }
    },
    [setIsOpen, setMenuItems],
  );

  if (props.childrenType !== MenuChildrenType.Manual) {
    throw new Error(`Non-manual children types are not implemented yet`);
  }

  const { componentWidth } = getComponentDimensions(props);

  const buttonProps: MenuComponentProps["buttonProps"] = {
    ...props.buttonProps,
    disabled: props.isDisabled,
    width: props.width,
    componentWidth,
    height: props.height,
    maxWidth: (getApplicableMaxWidth(props) ?? props.maxWidth)?.value,
    minWidth: getApplicableMinWidth(props)?.value,
  };

  return (
    <MenuComponent
      items={menuItems}
      buttonProps={buttonProps}
      isOpen={isOpen && !isDraggingOrResizingWidget}
      onClose={handleOnClose}
      onBeforeMenuOpen={handleBeforeMenuOpen}
      menuProps={props.menuProps}
      interactionKind={props.interactionKind}
      widgetId={props.widgetId}
      runEventHandlers={props.runEventHandlers}
    />
  );
};

export const MANUAL_CHILDREN_PROPERTY = {
  propertyName: "manualChildren",
  label: "Items",
  headerControlType: "ADD_MENU_ITEM",
  controlType: "MENU_ITEMS_INPUT",
  isBindProperty: false,
  isTriggerProperty: false,
};

class MenuWidget extends BaseWidget<MenuWidgetProps, never> {
  runEventHandlersBound: (payload: RunWidgetEventHandlers) => void;
  constructor(props: MenuWidgetProps) {
    super(props);
    this.runEventHandlersBound = this.runEventHandlers.bind(this);
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        sectionCategory: PropsPanelCategory.Content,
        children: [
          {
            propertyName: "buttonProps.text",
            label: "Label",
            helpText: "Sets the label of the button",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter button label text",
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Menu",
          },
          {
            hidden: () => true, // TODO: re-introduce this radio group when adding mapped children
            propertyName: "childrenType",
            label: "Menu items",
            helpText: "Menu items to be displayed in the menu",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                value: MenuChildrenType.Manual,
                label: "Manual",
              },
              {
                value: MenuChildrenType.Mapped,
                label: "Mapped",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          MANUAL_CHILDREN_PROPERTY,
        ],
      },
      {
        sectionName: "Button",
        sectionCategory: PropsPanelCategory.Content,
        showHeader: true,
        headerType: "Secondary",
        children: [
          paddingProperty({
            propertyName: "buttonProps.padding",
            label: "Padding",
            themeValue: ({ theme }) => {
              const value = getWidgetDefaultPadding(theme, {
                type: WidgetTypes.BUTTON_WIDGET,
              });
              const treatAsNull = value === ZERO_PADDING;
              return {
                treatAsNull,
                value: value,
              };
            },
          }),
          ...sizeProperties({
            widthSupportsFitContent: true,
            heightSupportsFitContent: true,
            hidePadding: true,
          }),
        ],
      },
      ...visibleProperties({ useJsExpr: true }),
      {
        sectionName: "Menu",
        sectionCategory: PropsPanelCategory.Layout,
        showHeader: true,
        headerType: "Secondary",
        children: [
          {
            propertyName: "menuProps.width",
            label: "Width",
            controlType: "SIMPLE_SIZE_CONTROL",
            defaultValue: Dimension.fitContent(180),
            optionsFunc: (props: MenuWidgetProps["menuProps"]) => [
              {
                label: "Fit content",
                value: "fitContent",
                subText: "Automatically adjust the width based on the content",
              },
              {
                label: "Pixels", // the value shown in the dropdown
                labelWhenSelected: "px", // the value shown when the option is selected
                value: "px",
                subText: "Width in pixels that remains constant",
              },
              {
                label: "Match parent",
                value: "fillParent",
                subText: "Match the button's width",
                hidden: () => props.orientation !== "vertical",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "Button",
        sectionCategory: PropsPanelCategory.Appearance,
        showHeader: true,
        headerType: "Secondary",
        sectionStyle: {
          marginTop: "4px", // extra margin because it's a sub-section right below a main section title
        },
        children: [
          {
            propertyName: "buttonProps.buttonStyle",
            label: "Button style",
            controlType: "DROP_DOWN",
            helpText: "Changes the style of the button",
            options: BUTTON_STYLE_OPTIONS,
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "buttonProps.showCaret",
            label: "Show caret",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            defaultValue: true,
            helpText:
              "Adds a caret icon which is conveniently aligned based on the text alignment",
          },
          iconProperty({
            propertyName: "buttonProps.icon",
          }),
          iconPositionProperty(
            {
              propertyName: "buttonProps.iconPosition",
            },
            "buttonProps.icon",
          ),
          textStyleCombinedProperty({
            textStyleParentDottedPath: "buttonProps",
            label: "Label style",
            defaultValueFn: {
              variant: () => DEFAULT_MENU_WIDGET_BUTTON_STYLE_VARIANT,
              "textColor.default": ({ props, theme }) =>
                textColorDefaultValue(props, theme),
            },
            additionalUserSelectableVariants: ["buttonLabel"],
          }),
          {
            propertyName: "buttonProps.textAlignment",
            propertyCategory: PropsPanelCategory.Appearance,
            helpText: "The horizontal alignment of the button label",
            label: "Label alignment",
            controlType: "RADIO_BUTTON_GROUP",
            defaultValue: "CENTER",
            hidden: (props: MenuWidgetProps) => {
              return props.width.mode === "fitContent";
            },
            options: [
              {
                icon: "LEFT_ALIGN",
                value: TextAlign.LEFT,
              },
              {
                icon: "CENTER_ALIGN",
                value: TextAlign.CENTER,
              },
              {
                icon: "RIGHT_ALIGN",
                value: TextAlign.RIGHT,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          backgroundColorProperty({
            propertyNamespaceDottedPath: "buttonProps",
            themeValue: ({ props }) =>
              props.buttonProps.buttonStyle === "PRIMARY_BUTTON"
                ? { value: "colors.primary500", treatAsNull: false }
                : { value: "transparent", treatAsNull: true },
          }),
          borderProperty({
            propertyNamespaceDottedPath: "buttonProps",
            defaultValueFn: ({ props, theme }) =>
              borderDefaultValue(props, theme),
            themeValue: ({
              props,
              theme,
            }: {
              props: MenuWidgetProps;
              theme: GeneratedTheme;
            }) => {
              return {
                value: borderDefaultValue(props, theme),
                treatAsNull:
                  props.buttonProps.buttonStyle !== "SECONDARY_BUTTON",
              };
            },
          }),
          borderRadiusProperty({
            propertyName: "borderRadius",
            propertyNamespaceDottedPath: "buttonProps",
            themeValue: ({
              theme,
              props,
            }: {
              theme: GeneratedTheme;
              props: MenuWidgetProps;
            }) => {
              return {
                value: createPerCornerBorderRadius(
                  theme.borderRadius ?? Dimension.px(4),
                ),
                treatAsNull:
                  props.buttonProps.buttonStyle === "TERTIARY_BUTTON",
              };
            },
            hidden: (props: MenuWidgetProps) =>
              props.buttonProps.buttonStyle === "TERTIARY_BUTTON",
          }),
        ],
      },
      {
        sectionName: "Menu",
        sectionCategory: PropsPanelCategory.Appearance,
        showHeader: true,
        headerType: "Secondary",
        children: [
          textStyleCombinedProperty({
            textStyleParentDottedPath: "menuProps",
            defaultValueFn: {
              variant: () => DEFAULT_MENU_WIDGET_MENU_ITEM_STYLE_VARIANT,
              "textColor.default": ({ theme }) => theme?.colors.neutral700,
            },
          }),
          {
            propertyName: "menuProps.orientation",
            label: "Orientation",
            helpText:
              "Whether the menu opens horizontally or vertically relative to the button",
            defaultValue: "vertical",
            controlType: "RADIO_BUTTON_GROUP",
            hideLabelsForUnselected: true,
            options: [
              {
                value: "vertical",
                label: "Vertical",
                icon: "VERTICAL_BOTTOM",
                showTooltip: true,
                tooltip: "Opens below when space is available",
              },
              {
                value: "horizontal",
                label: "Horizontal",
                icon: "ICON_RIGHT_ALIGN",
                showTooltip: true,
                tooltip: "Opens to the right when space is available",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            updateHook: ({
              props,
              propertyValue,
            }: {
              props: MenuWidgetProps;
              propertyValue: string | undefined;
            }) => {
              // Switch width mode from fillParent to fitContent when moving from vertical to horizontal
              const updates = [];
              if (
                props.menuProps.orientation === "vertical" &&
                propertyValue === "horizontal" &&
                props.menuProps.width.mode === "fillParent"
              ) {
                updates.push({
                  propertyPath: "menuProps.width",
                  propertyValue: Dimension.fitContent(
                    props.menuProps.width.value,
                  ),
                });
              }

              // Switch placement options
              if (
                props.menuProps.orientation === "vertical" &&
                propertyValue === "horizontal"
              ) {
                updates.push({
                  propertyPath: "menuProps.placement",
                  propertyValue: HORIZONTAL_PLACEMENT_OPTIONS[0].value,
                });
              } else if (
                props.menuProps.orientation === "horizontal" &&
                propertyValue === "vertical"
              ) {
                updates.push({
                  propertyPath: "menuProps.placement",
                  propertyValue: VERTICAL_PLACEMENT_OPTIONS[0].value,
                });
              }

              return updates;
            },
          },
          {
            propertyName: "menuProps.placement",
            label: "Alignment",
            helpText:
              "The alignment of the menu popover relative to the button that opens it",
            defaultValue: "bottom-start",
            controlType: "RADIO_BUTTON_GROUP",
            hideLabelsForUnselected: true,
            optionsFunc: (props: MenuWidgetProps["menuProps"]) => {
              return props.orientation === "vertical"
                ? VERTICAL_PLACEMENT_OPTIONS
                : HORIZONTAL_PLACEMENT_OPTIONS;
            },
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (props: MenuWidgetProps) =>
              props.menuProps.width.mode === "fillParent",
          },
        ],
      },
      {
        sectionName: "Interaction",
        sectionCategory: PropsPanelCategory.Interaction,
        children: [
          isDisabledProperty({ useJsExpr: true }),
          {
            propertyName: "interactionKind",
            label: "Open on",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                value: "click" satisfies InteractionKind,
                label: "Click",
              },
              {
                value: "hover" satisfies InteractionKind,
                label: "Hover",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      "buttonProps.text": VALIDATION_TYPES.TEXT,
      "buttonProps.icon": VALIDATION_TYPES.ICONS,
      "buttonProps.style": VALIDATION_TYPES.TEXT,
      "buttonProps.border": VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      "buttonProps.borderRadius": VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  getPageView() {
    return (
      <InternalMenuWidget
        {...this.props}
        runEventHandlers={this.runEventHandlersBound}
      />
    );
  }

  getWidgetType(): WidgetType {
    return "MENU_WIDGET";
  }
}

export default MenuWidget;

export const ConnectedMenuWidget = withMeta(MenuWidget);
